<template>
    <div class="permission-index">
        <navigationBar :routerList="this.$router.history.current.meta.breadcrumb" 
          @buttonClickEmit0="addOrUpPermission" 
          :buttonList="buttonList"/>


        <el-table :data="tableData" style="width: 100%" >
            <el-table-column prop="idNo" label="序号" width="80">
            </el-table-column>
            <el-table-column prop="code" label="编码" >
            </el-table-column>
            <el-table-column prop="title" label="注释" >
            </el-table-column>
            <el-table-column  label="操作" width="160">
            <template slot-scope="scope">
                    <el-button  size="mini" type="success" @click="addOrUpPermission(scope.row)">编辑</el-button>
                    <el-button  size="mini" type="danger" @click="deletePermissionById(scope.row)">删除</el-button>
            </template>
            </el-table-column>
        </el-table>
 
        <div class="Pagination" style="text-align: right;margin-top: 10px;">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage"
              :page-size="pageSize"
              layout="total, prev, pager, next"
              :total="count">
            </el-pagination>
        </div>

        <el-dialog :title="titleName" :visible.sync="dialogFormVisible" width="40%"  >
             <el-form ref="permissionForm" :model="permissionForm" :rules="rules" width="70px" >
                <el-form-item label="编码" prop="code" label-width="70px" style="width: 100%;">
                    <el-input  v-model="permissionForm.code" ></el-input>
                </el-form-item>
                <el-form-item label="注释" prop="title" label-width="70px" style="width: 100%;">
                    <el-input  v-model="permissionForm.title" ></el-input>
                </el-form-item>
                <el-form-item label="排序" prop="sort" label-width="70px" style="width: 100%;">
                    <el-input  v-model.number="permissionForm.sort"></el-input>
                </el-form-item>
 
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitPermission()" size="mini">提交</el-button>
                <el-button @click="dialogFormVisible = false" size="mini">取 消</el-button>
            </span>
        </el-dialog>

 
    </div>
</template>

<script>
import setting from '@/utils/config/index.js'
    import navigationBar from '@/components/navigationBar'
    import {deletePermissionById,addOrUpdatePermission,getAllPermission,getPermissionById} from '@/api/getUcenterData'
    export default {
        name:"Role",
        data () {
            return {

                titleName:"",
                options:{
                    border:true
                },
                tableData: [],
                dealCode:[],
                allMenu:[],
                checkedRow:[],
                loading: false,
                dialogVisible:false,
                permissionForm:{
                    id:'',
                    code:'',
                    title:'',
                    sort:'',
                },
                currentPage: 0,
                dialogFormVisible:false,
                pageSize: 10,
                count: 0,
                rules:{
                    sort:[{ type: 'number', message: '排序必须为数字值!'}],
                    title:[{required: true, message: '名称不能为空!'}],
                    code:[{required: true, message: '编码不能为空!'}]
                },
                buttonList:[
                  {
                    backgroundColor:'#52D1AF',
                    icon:'/static/img/add.png',
                    text:'新增',
                  }
                  
                ],
 
               
            }
        },
        created () {
            this.getQueryLsit();
            console.log("页面权限控制:",this.hasPermission('test_code_1'))
            console.log("页面权限控制:",this.hasPermission('test_code'))
        },
         mounted(){

        },

   
        components:{
          navigationBar
        },
        methods: {
            handleClose(){
                this.dialogVisible = false;
            },
            addOrUpPermission:function(data){
               
               if (data == null) {
                  this.titleName = "新增"
                  this.dialogFormVisible = true
                  this.permissionForm = {};
                }else{
                   this.titleName = "编辑"
                   this.dialogFormVisible = true
                   this.permissionForm = JSON.parse(JSON.stringify(data));
                }
            },
            submitPermission:function(){
                this.$refs.permissionForm.validate((valid) => {
                    console.log("valid",valid)
                    if (valid) {
                        let param={
                            id:this.permissionForm.id,
                            code:this.permissionForm.code,
                            sort:this.permissionForm.sort,
                            title:this.permissionForm.title,
                        }
                        console.log("param",param)
                        addOrUpdatePermission(param).then((res)=>{
                            console.log("res",res)
                            if(res.resultCode == 1){
                                this.$message({
                                    message: "成功",
                                    type: "success",
                                });
                            }else{
                                this.$message({
                                    message: res.resultMsg,
                                    type: "error",
                                });
                            }
                            this.getQueryLsit();
                            this.dialogVisible = false;
                        })
                    }
                    this.dialogFormVisible = false;
                });
            },
            getQueryLsit:function(){
                let param = {
                    pageNo: this.currentPage,
                    pageSize: this.pageSize,
                };
                getAllPermission(param).then((resData)=>{
                      console.log("resData",resData)

                     if(resData.resultCode==1){
                        var userList = resData.data.list
                        for(var i = 0 ; i < userList.length; i++){
                            userList[i].idNo = (this.currentPage - 1) * this.pageSize + i+1;  
                        }
                        this.count = resData.data.total;
                        this.tableData = userList;
                    }else{
                        this.$message({message: resData.resultMsg,type: 'error'});
                    }

                })
            },
            handleSelectionChange:function(value){
                //获取选中
                this.checkedRow = value;
            },
            handleCurrentChange:function(currentPage){
                this.currentPage = currentPage;
                 this.getQueryLsit();
            },
  
            deletePermissionById(row) {
                this.$confirm('确认删除该记录吗?', '提示', {
                type: 'warning'
              }).then(() => {
                let para = { id:row.id };
                deletePermissionById(para).then((res) => {
                     if(res.resultCode == 1){
                         this.$message({
                            message: "成功",
                            type: "success",
                        });
                     }else{
                         this.$message({
                            message: "失败",
                            type: "error",
                        });
                     }
                     this.getQueryLsit();
                });
              })

                     
            }
            

        }
    }
    
</script>
<style lang="scss">
.permission-index{
    .el-table thead {
        color: #21293B;
        font-size: 0.8rem;
    }
    .el-table th, .el-table thead {
        background-color: #E9ECEE;
        height:3rem;
    }
    .el-table {
        font-size: 0.8rem;
    }
    .paging{
       font-size: 0.8rem; 
       display: inline-block;
    }
    .el-pagination {
      display: inline-block
    }
    .img{
      width:1rem;
      height:1rem;
    }
    .el-table .cell, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
        padding-left: 1rem;
    }
    .el-form--inline .el-form-item {
        margin-right: 1.5rem;
    }

    .el-table thead {
        font-size: 0.8rem;
    }
    .el-dialog__header {
        background: #E9ECEE;
        padding: 1rem;
    }
    .el-dialog__headerbtn {
        top: 1.25rem;
    }
    .el-dialog__title{
        font-weight: bold;
    }
    .el-table .cell, .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
        padding-left: 1rem;
    }
    .el-form--inline .el-form-item {
        margin-right: 1.5rem;
    }
}
    
</style>